import React from 'react'
import * as Styled from '../styles/Home/Preview.styled'
import Fade from 'react-reveal/Fade';

export const HomePreview = (props) => {
  return (
    <>
      {window.innerWidth <= 520 ?
        <Fade bottom>
          <Styled.PreviewWrapper href="/artwork">
            <Styled.Preview image={props.image} />
          </Styled.PreviewWrapper>
        </Fade> :
        <Styled.PreviewWrapper href="/artwork">
          <Styled.Preview image={props.image} />
          <span className='divisor'>+ Ver más</span>
        </Styled.PreviewWrapper>
      }
    </>
  )
}