import styled from "styled-components";

export const ArtWorkDetailImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const ArtWorkDetailImage = styled.img`

  @media(max-width: 520px){
    padding: 30px 0;
  }
`

export const ArtWorkDetailLogo = styled.img`
  margin-top: 40px;
  width: 250px;

  @media (max-width: 520px){
    display: none;
  }
`

export const ArtWorkDetailDescription = styled.div`

  h2 {
    margin-top: 20px;
  }

  p {
    margin-top: 15px;
    color: gray;
  }
`

export const ArtWorkSpecs = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  h6 {
    color: gray;
  }
`

export const ArtWorkNavi = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  font-weight: bold;

  a {
    color: black;
    margin-bottom: 5px;
  }

`