import React from 'react'
import { HomePreview } from './HomePreview'
import { PreviewGallery } from '../styles/Home/HomeGallery.styled'
import intro_wp from '../../images/intro-wall.jpg'
import jOffice from '../../images/artwork/j_office.jpg'
import mestiza from '../../images/artwork/mestiza.jpg'
import orangeB from '../../images/artwork/orange-blues.jpg'
import pink from '../../images/artwork/pink.jpg'
import olafuego from '../../images/artwork/olafuego.jpg'

export const HomeGallery = () => {
  return (
    <PreviewGallery>
      <HomePreview image={intro_wp} />
      <HomePreview image={orangeB} />
      <HomePreview image={mestiza} />
      <HomePreview image={pink} />
      <HomePreview image={jOffice} />
      <HomePreview image={olafuego} />
    </PreviewGallery>
  )
}