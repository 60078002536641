import React from 'react'
import { Row, Col } from 'reactstrap'
import { ArtWorkDetailImage, ArtWorkDetailLogo } from '../styles/Artwork/ArtWorkDetail.styled'
import AboutmeInfo from './AboutmeInfo'
import aboutme from '../../images/aboutme.jpg'
import alove_logo from '../../images/alovesart_logo.svg'
import { ArtWorkNavi } from '../styles/Artwork/ArtWorkDetail.styled'
import { NavLink } from 'react-router-dom'
import { AboutmeNav } from '../styles/Aboutme/Aboutme.styled'
import useWindowDimensions from '../useWindowDimensions'


const Aboutme = () => {

  let imgWidth = "auto"
  let imgHeight = "100%"
  const { width } = useWindowDimensions()

  if (width <= 520) {
    imgWidth = "100%"
    imgHeight = "100%"
  }

  return (
    <>
      <Row style={{ height: "100%" }}>
        <Col xs="12" sm={{ offset: 1, size: 3 }}>
          <AboutmeNav>
            <ArtWorkDetailLogo src={alove_logo} />
            {window.innerWidth > 520 &&
              <ArtWorkNavi>
                <NavLink activeClassName="active" to="/">
                  Home
                </NavLink>
                <NavLink activeClassName="active" to="/artwork">
                  Artwork
                </NavLink>
                <NavLink activeClassName="active" to="/contact">
                  Contacto
                </NavLink>
                <NavLink activeClassName="active" to="/aboutme">
                  Sobre mí
                </NavLink>
              </ArtWorkNavi>
            }
          </AboutmeNav>
        </Col>
        <Col xs="12" sm="3" >
          <AboutmeInfo />
        </Col>
        <Col xs="12" sm="5" style={{ height: "100%" }} >
          <ArtWorkDetailImage height={imgHeight} width={imgWidth} src={aboutme} />
        </Col>
      </Row>
    </>
  )
}

export default Aboutme;