import React from 'react'
import FetchArtwork from '../FetchArtwork'

const Acrilic = () => {

  return (
    <>
      <FetchArtwork artType="acrilico" />
    </>
  )
}

export default Acrilic;