import styled from "styled-components";
import intro_wp from "../../../images/intro-wall.jpg";

export const IntroWrapper = styled.section`

    .col-md-8 {
        padding-right: 0;
        padding-left: 0;
    }
`

export const Wallpaper = styled.div`
    background-image: url(${intro_wp});
    background-size: cover;
    background-position: center;
    height: 100vh;

    @media (max-width: 520px){
        height: 200px;
    }
`

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: space-around;

    @media (max-width: 520px){
        height: 65vh;
    }

    a:hover{
        color: transparent;
        text-decoration: none;
    }
`
export const Logo = styled.img`
    width: ${props => props.width || "350px"} ;

    @media (max-width: 520px){
        width: ${props => props.mobile || "280px"};
    }
`

export const Entrance = styled.div`
    display: flex;
    justify-content: space-between;
    width: 350px;
    border-bottom: 1px solid black;

    @media (max-width: 520px){
        width: 280px;
    }
`

export const SocialMedia = styled.div`
    display: flex;
    align-items: center;

    img {
        height: 20px;
        margin-left: 10px;
    }
`

export const GotoSite = styled.div`
    color: gold;

    p {
        font-size: 1.5rem;
        margin-bottom: 0;
        font-family: FengardoNeue_Regular;
    }
`