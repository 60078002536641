import React from 'react'
import FetchArtwork from '../FetchArtwork'


const Cases = () => {

  return (
    <>
      <FetchArtwork artType="fundas" />
    </>
  )
}

export default Cases;