import React from 'react'
import { Row, Col } from 'reactstrap'
import { Navi } from '../Navi'
import { Link } from 'react-router-dom'
import { ArtWorkSections, ArtWorkPiece } from '../styles/Artwork/ArtWorkSections.styled'
import flamingos from '../../images/artwork/flamingosis.jpg'
import intro_wall from '../../images/intro-wall.jpg'
import orange_blues from '../../images/artwork/orange-blues.jpg'
import case_1 from '../../images/cases/case_1.jpg'

const ArtWork = () => {

  const common_props = {
    width: "23vw",
    height: "100%",
    box_shadow: true
  }

  return (
    <>
      <Navi />
      <ArtWorkSections>
        <Row>
          <Col xs="12" md="3">
            <Link to='watercolor'>
              <ArtWorkPiece image={flamingos} {...common_props} >
                <h2>Acuarelas</h2>
              </ArtWorkPiece>
            </Link>
          </Col>
          <Col xs="12" md="3">
            <Link to='acrilic'>
              <ArtWorkPiece image={intro_wall} {...common_props}>
                <h2>Acrílico</h2>
              </ArtWorkPiece>
            </Link>
          </Col>
          <Col xs="12" md="3" >
            <Link to='oleo'>
              <ArtWorkPiece image={orange_blues} {...common_props}>
                <h2>Óleo</h2>
              </ArtWorkPiece>
            </Link>
          </Col>
          <Col xs="12" md="3">
            <Link to='cases'>
              <ArtWorkPiece image={case_1} {...common_props}>
                <h2>Fundas</h2>
              </ArtWorkPiece>
            </Link>
          </Col>
        </Row>
      </ArtWorkSections>
    </>
  )
}

export default ArtWork;