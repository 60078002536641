import React from 'react'
import { ContactInfoWrapper } from '../styles/Contact/Contact.styled';
import logo from "../../images/aboutme_logo.png"


const ContactInfo = () => {
  return (
    <ContactInfoWrapper>
      <h4>¡Contáctame!</h4>
      <h4>asenet.solis@gmail.com</h4>
      <img src={logo} alt="alove_logo" />
    </ContactInfoWrapper>
  )
}

export default ContactInfo;