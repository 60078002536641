import styled from "styled-components";

export const PreviewGallery = styled.section`
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    height: 65vh;

    @media (max-width: 768px){
        flex-direction: column;
        margin-top: 5px;
        height: auto;
    }
`