import React from 'react'
import { Navi } from '../Navi'
import {
  ArtWorkDetailDescription,
  ArtWorkDetailLogo,
  ArtWorkSpecs,
  ArtWorkNavi
} from '../styles/Artwork/ArtWorkDetail.styled'
import { NavLink } from 'react-router-dom'

import alove_logo from "../../images/alovesart_logo.svg"

const ArtworkDetailInfo = ({ artTitle, artDescription, artTechnique, artMeasures, artYear }) => {

  return (
    <>
      {window.innerWidth <= 991 && <Navi />}
      {window.innerWidth >= 992 && <ArtWorkDetailLogo src={alove_logo} />}
      <ArtWorkDetailDescription>
        <h2>{artTitle}</h2>
        <p>{artDescription}</p>
      </ArtWorkDetailDescription>
      <ArtWorkSpecs>
        <h5>Técnica</h5>
        <h6>{artTechnique}</h6>
      </ArtWorkSpecs>
      <ArtWorkSpecs>
        <h5>Medida</h5>
        <h6>{artMeasures}</h6>
      </ArtWorkSpecs>
      <ArtWorkSpecs>
        <h5>Año</h5>
        <h6>{artYear}</h6>
      </ArtWorkSpecs>
      {window.innerWidth >= 992 &&
        <ArtWorkNavi>
          <NavLink activeClassName="active" to="/">
            Home
          </NavLink>
          <NavLink activeClassName="active" to="/artwork">
            Artwork
          </NavLink>
          <NavLink activeClassName="active" to="/contact">
            Contacto
          </NavLink>
          <NavLink activeClassName="active" to="/aboutme">
            Sobre mí
          </NavLink>
        </ArtWorkNavi>
      }
    </>

  )
}

export default ArtworkDetailInfo;