import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import emailjs from '@emailjs/browser';
import { ContactFormWrapper } from '../styles/Contact/Contact.styled';
import Swal from 'sweetalert2'

const ContactForm = () => (
  <>
    <Formik
      initialValues={{ from_name: '', from_email: '', from_subject: '', message: '' }}
      validate={values => {
        const errors = {};
        if (!values.from_email) {
          errors.from_email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.from_email)
        ) {
          errors.from_email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE, process.env.REACT_APP_EMAILJS_TEMPLATE, { ...values }, process.env.REACT_APP_EMAILJS_PK)
          .then((result) => {
            setSubmitting(false)
            resetForm();
            Swal.fire({
              title: 'Mensaje enviado!',
              text: 'En breve nos estaremos comunicando.',
              icon: 'success',
              confirmButtonText: 'Ok'
            })
          }, (error) => {
            console.log(error.text);
          });

      }}
    >
      {({ isSubmitting }) => (
        <>
          <ContactFormWrapper>
            <Form>
              <h2>¡HOLA!</h2>
              <Field type="text" name="from_name" placeholder="Nombre" />
              <ErrorMessage name="from_name" component="div" />
              <Field type="email" name="from_email" placeholder="Email" />
              <ErrorMessage name="from_email" component="div" />
              <Field type="text" name="from_subject" placeholder="Asunto" />
              <ErrorMessage name="from_subject" component="div" />
              <Field type="textarea" name="message" placeholder="Mensaje" />
              <ErrorMessage name="message" component="div" />
              <button type="submit" style={{ display: isSubmitting ? "none" : "block" }}>
                Enviar
              </button>
              <button style={{ display: isSubmitting ? "block" : "none" }} >
                Enviando...
              </button>
            </Form>
          </ContactFormWrapper>
        </>
      )}
    </Formik>
  </>
);

export default ContactForm;