import React from 'react'
import ContactForm from './ContactForm';
import { Navi } from '../Navi';
import { ContactWrapper, ContactFormContainer, ContactImageWrapper } from '../styles/Contact/Contact.styled';
import contact_img from '../../images/contact_img.jpg';
import ContactInfo from './ContactInfo';

const Contact = () => {
  return (
    <>
      <Navi />
      <ContactWrapper>
        <ContactFormContainer>
          <ContactForm />
        </ContactFormContainer>
        <ContactImageWrapper>
          <img src={contact_img} alt='Contact_image' />
        </ContactImageWrapper>
        <ContactInfo />
      </ContactWrapper>
    </>
  )
}

export default Contact;