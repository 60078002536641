import React from 'react'
import { Navi } from "../Navi"
import { HomeGallery } from './HomeGallery'

const Home = () => {
  return (
    <>
      <Navi />
      <HomeGallery />
    </>
  )
}

export default Home;