import React from 'react'
import FetchArtwork from '../FetchArtwork'


const Oil = () => {

  return (
    <>
      <FetchArtwork artType="oleo" />
    </>
  )
}

export default Oil;