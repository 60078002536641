import './App.css';
import Home from './components/home/Home';
import ArtWork from './components/Artwork/ArtWork';
import Oil from './components/Oil/Oil';
import Cases from './components/Cases/Cases';
import Acrilic from './components/Acrilic/Acrilic';
import Watercolor from './components/Watercolor/Watercolor';
import ArtworkDetail from './components/ArtworkDetail/ArtworkDetail';
import { Container } from 'reactstrap';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Aboutme from './components/Aboutme/Aboutme';
import ContactForm from './components/Contact/Contact';

function App() {
  return (
    <Container fluid>
      <BrowserRouter>
        <Routes>
          <Route path='artwork' element={<ArtWork />} />
          <Route path='artwork/oleo' element={<Oil />} />
          <Route path='artwork/cases' element={<Cases />} />
          <Route path='artwork/acrilic' element={<Acrilic />} />
          <Route path='artwork/watercolor' element={<Watercolor />} />
          <Route path='artwork/watercolor/:id' element={<ArtworkDetail />} />
          <Route path='artwork/oleo/:id' element={<ArtworkDetail />} />
          <Route path='artwork/acrilic/:id' element={<ArtworkDetail />} />
          <Route path='artwork/cases/:id' element={<ArtworkDetail />} />
          <Route path='aboutme' element={<Aboutme />} />
          <Route path='contact' element={<ContactForm />} />
          <Route path='/' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
}

export default App;
