import styled from "styled-components";

export const AboutmeInfoContainer = styled.div`

  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 520px){
    display: block;
  }

`

export const AboutmeAloveLogo = styled.div`

  display: flex;
  justify-content: flex-end;

  img {
    height: 100px;
  }

`

export const AboutmeNav = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;

`