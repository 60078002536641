import styled from 'styled-components'

export const ArtWorkSections = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  height: 65vh;

  a:hover {
    text-decoration: none;
  }

  @media (max-width: 768px){
    margin-top: 5px;
    height: 100%;
  }
`

export const ArtWorkPiece = styled.div`
  background-image: url(${props => props.image});
  height: ${props => props.height};
  width: ${props => props.width};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  padding-top: 30px;
  text-align: center;
  box-shadow: ${props => props.box_shadow ? "inset 0px 78px 70px 2px rgba(0,0,0,0.58)" : 0} ;
  border-radius: 5px;

  h2 {
    color: white;
  }

  @media (max-width: 768px){
    height: 250px;
    width: auto;
    margin: 20px 0;
  }
`

export const ArtWorkRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 30px;
`