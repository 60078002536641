import styled from "styled-components";

export const PreviewWrapper = styled.a`
    height: 65vh;
    width: 16vw;
    transition: 0.5s;

    .divisor{
        display: none;
        border-top: 2px solid #d6bd37;
        margin-top: 20px;
        color: #d6bd37;
        font-weight: bold;
    }

    @media (min-width: 768px){
        &:hover {
            width: 65%;
            transform: scale(1);
            transition: 0.5s;
            box-shadow: 0px -3px 22px -3px rgba(0,0,0,0.58);
            -webkit-box-shadow: 0px -3px 22px -3px rgba(0,0,0,0.58);
            -moz-box-shadow: 0px -3px 22px -3px rgba(0,0,0,0.58);

            .divisor {
                display: block;
            }
        }
    }

    @media (max-width: 768px){
        width: 100%;
        height: 43vh;
        margin-bottom: 20px;
        display: block;
    }

`
export const Preview = styled.div`
    background-image: url(${props => props.image});
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
`

export const PreviewLink = styled.link`
    
`