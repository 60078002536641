import React from 'react'
import alove_heart from "../images/alove_heart.png"
import { Loader, AloveLoaderContainer } from './styles/AloveLoader.styled';

const AloveLoader = () => {
  return (
    <>
      <AloveLoaderContainer>
        <Loader img={alove_heart} />
      </AloveLoaderContainer>
    </>
  )
}

export default AloveLoader;