import React from 'react'
import { Navi } from '../Navi'
import { ArtWorkDetailDescription } from '../styles/Artwork/ArtWorkDetail.styled'
import { AboutmeInfoContainer, AboutmeAloveLogo } from '../styles/Aboutme/Aboutme.styled'
import logo from "../../images/aboutme_logo.png"

const AboutmeInfo = () => {

  return (
    <>
      {window.innerWidth < 520 && <Navi />}
      <AboutmeInfoContainer>
        <ArtWorkDetailDescription>
          <p>Los sueños de las personas cobran vida a través de cualquier camino, unos usan letras, otros cámaras
            y otro pinceles como yo, transformándolos en una manera de expresión. Arte.
          </p>
          <p>
            Cada idea, cada brochazo e incluso cada color es irrepetible, por lo que cada pieza es única.
          </p>
          <p>
            Espero lograr transmitir el sueño que he plasmado para ti.
          </p>
        </ArtWorkDetailDescription>
        <AboutmeAloveLogo>
          <img src={logo} alt="alove_logo" />
        </AboutmeAloveLogo>
      </AboutmeInfoContainer>
    </>

  )
}

export default AboutmeInfo;