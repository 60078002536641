import { React, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { ArtWorkDetailImage, ArtWorkDetailImageContainer } from '../styles/Artwork/ArtWorkDetail.styled'
import ArtworkDetailInfo from './ArtworkDetailInfo'
import AloveLoader from '../AloveLoader'
import useWindowDimensions from '../useWindowDimensions'

const ArtworkDetail = () => {
  const { id } = useParams()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [imgHeight, setImgHeight] = useState(null)
  const [imgWidth, setImgWidth] = useState("auto")
  const { width } = useWindowDimensions()

  useEffect(() => {
    const requestHeaders = new Headers()
    requestHeaders.append('Content-Type', 'application/json')
    requestHeaders.append('Authorization', `Basic ${btoa(process.env.REACT_APP_USER + ":" + process.env.REACT_APP_PASS)}`)

    const getData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/artwork/${id}/`, {
          method: 'GET',
          headers: requestHeaders,
        })

        let actualData = await response.json();
        let new_img = new Image()

        new_img.src = actualData.photo_url
        let new_img_heigth = new_img.height
        let new_img_width = new_img.width

        if (width <= 520) {
          if (new_img_heigth > new_img_width) {
            setImgHeight("420px")
          } else {
            setImgWidth("100%")
          }
        } else {
          if (new_img_heigth > new_img_width) {
            setImgWidth("500px")
            setImgHeight("700px")
          } else {
            setImgHeight("525px")
            setImgWidth("750px")
          }
        }

        setData(actualData);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }

    }

    getData()
  }, [id, width]);
  return (
    <>
      {loading && <AloveLoader />}
      {error && <p>Error ocurred:- Something went wrong fetching data. Please send an email to loeza1992@gmail.com</p>}
      {data &&
        <Row style={{ height: '100vh' }}>
          <Col xs="12" lg={{ offset: 1, size: 3 }} >
            <ArtworkDetailInfo
              artTitle={data.title}
              artDescription={data.description}
              artTechnique={data.technique}
              artMeasures={data.measures}
              artYear={data.year}
            />
          </Col>
          <Col xs="12" lg="8" >
            <ArtWorkDetailImageContainer>
              <ArtWorkDetailImage height={imgHeight} width={imgWidth} id="artwork_img" src={data.photo_url} />
            </ArtWorkDetailImageContainer>
          </Col>
        </Row>
      }
    </>
  )
}

export default ArtworkDetail