import styled from "styled-components";

export const Loader = styled.div`
  
  width: 285px;
  height: 285px;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  animation: pulse 1s linear infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    50%{
      transform: scale(1.3);
      opacity:1;
    }
    100%{
      transform: scale(1);
      opacity:0;
    }
}
`

export const AloveLoaderContainer = styled.div`

  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`