import React, { useState } from 'react'
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem
} from 'reactstrap'
import { NavLink } from 'react-router-dom'

import { Logo as StyledLogo } from './styles/intro/intro.styled'
import alove_logo from "../images/alovesart_logo.svg"


export const Navi = () => {

  const [collapsed, setCollapsed] = useState(true)

  const toggleNavbar = () => setCollapsed(!collapsed)

  return (
    <>
      <Navbar
        color="faded"
        expand="lg"
        light
      >
        <NavbarBrand id='alove_logo' href="/">
          <StyledLogo src={alove_logo} width="280px" mobile="100px" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={!collapsed} navbar>
          <Nav
            className="d-flex justify-content-around w-100"
            navbar
          >
            <NavItem>
              <NavLink activeClassName="active" to="/">
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="active" to="/artwork">
                Artwork
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="active" to="/contact">
                Contacto
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="active" to="/aboutme">
                Sobre mí
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  )
}