import { useState, useEffect } from 'react';
import { Navi } from './Navi';
import { Col } from 'reactstrap'
import { Link } from 'react-router-dom';
import { ArtWorkPiece, ArtWorkRow } from './styles/Artwork/ArtWorkSections.styled';
import AloveLoader from './AloveLoader';

const FetchArtwork = ({ artType }) => {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const common_props = {
    box_shadow: false,
    height: "300px"
  }

  useEffect(() => {
    const requestHeaders = new Headers()
    requestHeaders.append('Content-Type', 'application/json')
    requestHeaders.append('Authorization', `Basic ${btoa(process.env.REACT_APP_USER + ":" + process.env.REACT_APP_PASS)}`)

    const getData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/artwork/?artworkType=${artType}`, {
          method: 'GET',
          headers: requestHeaders,
        })

        let actualData = await response.json();
        setData(actualData.results);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }

    }

    getData()
  }, [artType]);

  return (
    <>
      <Navi />
      {loading && <AloveLoader />}
      {error && <p>Error ocurred: {error} - Something went wrong fetching data. Please send an email to loeza1992@gmail.com</p>}
      <ArtWorkRow>
        {data &&
          data.map(({ id, photo_url }) => (
            <Col xs="12" sm="3" style={{ margin: '5px 0' }}>
              <Link to={`${id}`} >
                <ArtWorkPiece image={photo_url} {...common_props} />
              </Link>
            </Col>
          ))
        }
      </ArtWorkRow>
    </>
  )
}

export default FetchArtwork;