import styled from "styled-components";
//import contact_img from "../../../images/contact_img.jpg"

export const ContactWrapper = styled.section`
  display: flex;
  justify-content: center;
  height: 500px;


  @media (max-width: 600px) {
    display: block;
    height: auto;
  }
`

export const ContactFormContainer = styled.div`
    width: 400px;

    @media (max-width: 600px){
      width: 100%;
      height: 500px;
  }
`

export const ContactFormWrapper = styled.div`
  
  width: 100%;
  height: 100%;
  background-color: #F1D774;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  form {
    display: flex;
    flex-direction: column;
    width: 85%;
    color: white;

    h2 {
      font-weight: bold;
    }

    input {
      border-style: none none solid none;
      border-bottom: solid white;
      background: transparent;
      padding-left: 20px;
      padding: 15px 0;

    }
    input:focus {
      outline: none !important;
    }
    
    input::placeholder {
      color: white;
      opacity: 1;
    }

    button {
      background: transparent;
      border: none;
      color: white;
      font-weight: bold;
      text-align: left;
      margin-top: 25px;
    }
  }
`

export const ContactImageWrapper = styled.div`
  width: 300px;

  img {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  width: 400px;

  img {
    margin-top: 30px;
    height: 70px;
  }



  @media (min-width: 600px) and (max-width: 768px){
    h4 {
      font-size: 1rem;
    }
  }

  @media (max-width: 600px){
    margin-bottom: 20px;
    height: 300px;
    width: auto;

  }
`